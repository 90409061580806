import React from 'react'

export function userLettersAvatar(fullName: string) {
  if (!fullName) return ''

  const firstLetterFirstName = fullName.substring(0, 1)

  const splitName = fullName.split(' ')
  const firstLetterLastName = splitName[splitName.length - 1].substring(0, 1)

  if (splitName.length > 1) {
    return (firstLetterFirstName + firstLetterLastName).toUpperCase()
  }

  return firstLetterFirstName.toUpperCase()
}

export const blockedDomains = [
  'gmail.com',
  'hotmail.com',
  'live.com',
  'yahoomail.com',
  'yahoo.com',
  'terra.com',
  'outlook.com',
  'icloud.com',
  'bol.com',
]

export const isB2BEmployee = (email: string) => {
  const domain = email.split('@')[1]
  return domain === 'b2bstack.com.br'
}

export function isEven(numberToCheck: number) {
  return numberToCheck % 2 === 0
}

export const handleGetCSVFile = ({
  handleCSVUpload,
  showAlert,
}: {
  handleCSVUpload: (file: File) => void
  showAlert: (message: string, type: string) => void
}) => {
  const acceptableFormats =
    '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'

  const input = document.createElement('input')
  input.accept = acceptableFormats
  input.type = 'file'
  input.click()

  input.onchange = () => {
    const file = input.files?.item(0)
    if (file) {
      showAlert('Carregando...', 'info')
      handleCSVUpload(file)
    }
  }
}

export const handleGetPDFFIle = ({
  handlePDFUpload,
}: {
  handlePDFUpload: (file: File) => Promise<void>
}) => {
  const acceptableFormats = '.pdf, application/pdf'

  const input = document.createElement('input')
  input.accept = acceptableFormats
  input.type = 'file'

  input.click()

  input.onchange = () => {
    const file = input.files?.item(0)
    if (file) {
      handlePDFUpload(file)
    }
  }
}

export function fileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      const base64 = reader.result?.toString().split(',')[1]
      if (base64) {
        resolve(base64)
      } else {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject('Error converting file to base64.')
      }
    }
    reader.onerror = (error) => {
      reject(error)
    }
  })
}

export function downloadBase64(base64Content: string, fileName: string) {
  const binaryContent = atob(base64Content)
  const decoder = new TextDecoder('utf-8')
  const csvContent = decoder.decode(
    new Uint8Array([...binaryContent].map((char) => char.charCodeAt(0))),
  )
  const url = URL.createObjectURL(
    new Blob([csvContent], { type: 'text/csv;charset=utf-8;' }),
  )
  const downloadLink = document.createElement('a')

  downloadLink.href = url
  downloadLink.download = fileName
  downloadLink.click()

  URL.revokeObjectURL(url)
}

export function downloadBase64PDF(base64Content: string, fileName: string) {
  const binaryContent = atob(base64Content)
  const array = new Uint8Array(binaryContent.length)
  for (let i = 0; i < binaryContent.length; i++) {
    array[i] = binaryContent.charCodeAt(i)
  }
  const url = URL.createObjectURL(
    new Blob([array], { type: 'application/pdf' }),
  )
  const downloadLink = document.createElement('a')

  downloadLink.href = url
  downloadLink.download = fileName
  downloadLink.click()

  URL.revokeObjectURL(url)
}

export const handleClickOutside = (
  event:
    | React.MouseEvent<HTMLDivElement, MouseEvent>
    | React.TouchEvent<HTMLDivElement>,
  onClickClose: () => void | void,
) => {
  if (event.target.getAttribute('role') === 'presentation') {
    onClickClose()
  }
}

export function generateRandomPassword() {
  const length = 16
  const charset =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  let retVal = ''

  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n))
  }

  return retVal
}

export const handleSortClick = (
  key: string,
  setOrder: (prevOrder: any) => void,
) => {
  setOrder((prevOrder: any) => ({
    ...prevOrder,
    orderDirection: prevOrder.orderDirection === 'asc' ? 'desc' : 'asc',
    orderKey: key,
  }))
}

export const getLastItemFromArray = (array: any[]) => array[array.length - 1]

interface ColorScheme {
  color: string
  borderColor: string
}

export const generateColorScheme = (numColors: number): ColorScheme[] => {
  const referenceColors: ColorScheme[] = [
    { color: '#D6E7FF', borderColor: '#4989E9' },
    { color: '#FDE4FF', borderColor: '#E368FF' },
    { color: '#CDCDCD', borderColor: '#141414' },
    { color: '#F0F0F0', borderColor: '#A4A4A4' },
    { color: '#ECF5E5', borderColor: '#6EBD6D' },
    { color: '#E0FAF0', borderColor: '#86E4C0' },
    { color: '#FFE4FF', borderColor: '#FF92C' },
    { color: '#E4E8FF', borderColor: '#7D9EFF' },
  ]

  const colorSchemes: ColorScheme[] = []

  for (let i = 0; i < numColors; i++) {
    const colorScheme = referenceColors[i % referenceColors.length]
    colorSchemes.push({
      color: colorScheme.color,
      borderColor: colorScheme.borderColor,
    })
  }

  return colorSchemes
}

const numColors = 10 // or any other number you desire
export const departmentColors = generateColorScheme(numColors)
